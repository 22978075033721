* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: hemaBook, hemaBold, hemaDemiBold, arial, sans-serif;
}

@font-face {
  font-family: 'hemaBook';
  src: local('hemaBook'), url('./assets/fonts/hema-book.otf') format('opentype');
}

@font-face {
  font-family: 'hemaBold';
  src: local('hemaBold'), url('./assets/fonts/hema-bold.otf') format('opentype');
}

@font-face {
  font-family: 'hemaDemiBold';
  src: local('hemaDemiBold'),
    url('./assets/fonts/hema-demibold.otf') format('opentype');
}
